.question {
  display: flex;
  flex-direction: column;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  margin: 10px 0 0 0;
  box-sizing: border-box;
  padding: 8px;
  position: relative;
}
.question:nth-child(5n):not(:last-child) {
  margin-bottom: 60px;
}
.question:nth-child(5n):not(:last-child)::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -30px;
  border: 2px solid #cbcbcb;
}